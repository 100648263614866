































import { Component, Prop, Vue } from 'vue-property-decorator'
import IconPlus from '@tada/icons/dist/IconPlus.vue'
import IconTimes from '@/components/UI/icons/IconTimes.vue'
import { format } from 'quasar'

const { capitalize } = format

@Component({ name: 'EditMenuSection' })
export default class EditMenuSection extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  }) readonly value!: boolean

  @Prop({
    type: String,
    required: true,
  }) readonly label!: string

  @Prop({
    type: Boolean,
    default: false,
  }) readonly hideControl!: boolean

  // functional icons signatures
  iconPlus!: any
  iconTimes!: any

  get computedLabel (): string {
    return capitalize(this.label)
  }

  created () {
    this.iconPlus = IconPlus
    this.iconTimes = IconTimes
  }
}
